<template>
  <div class="community-page">
    <CommunityDetail
      :communityInfo="communityInfo"
      :nftLevel="nftLevel"
      sourceType="1"
    ></CommunityDetail>
  </div>
</template>

<script>
import CommunityDetail from './components/CommunityDetail.vue'
import { queryCommunityInfo } from '../../services/community'
import { queryMyWallet } from '../../services/wallet'
export default {
  name: '',
  components: { CommunityDetail },
  data () {
    return {
      communityInfo: {},
      nftLevel: 0
    }
  },
  computed: {
    communityNo () {
      return this.$route.query.communityNo
    }
  },
  watch: {
    communityNo (val) {
      this.getCommunityInfo()
    }
  },
  methods: {
    async getCommunityInfo () {
      const params = {
        communityNo: this.communityNo
      }
      // const resp = await queryCommunityList(params)
      const resp = await queryCommunityInfo(params)
      if (!resp.success) return
      this.communityInfo = resp.result
    },
    // 获取是否有骑士卡
    async getIsKft () {
      const resp = await queryMyWallet()
      if (!resp.success) return
      this.nftLevel = resp.result.nftLevel || 0
    }
  },
  mounted () {
    this.getCommunityInfo()
    this.getIsKft()
  }
}
</script>

<style scoped lang="scss">
.community-page {
  padding: 13px;
  box-sizing: border-box;
  font-size: 12px;
}
</style>
